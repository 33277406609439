import axios from "axios";
import Vue from "vue";
import decode from 'jwt-decode';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL,
  requestCert: false,
  strictSSL: false,
  rejectUnauthorized: false,
});

axiosInstance.interceptors.request.use(
  function(config) {
    const token = window.sessionStorage.token;
    if (token) {
      config.headers.Authorization = token;
    } else{
      config.headers.Authorization = "Bearer " + process.env.VUE_APP_TOKEN
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(response => {
  if(response.status == 401){
     this.$store.dispatch("LOGOUT");
     this.$router.push({ name: "login" });
  }
  if(response.status == 429){
    this.$store.dispatch("LOGOUT");
    this.$router.push({ name: "login" });
 }
  return response
})


export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint, body) {
    return axiosInstance.delete(endpoint, body);
  },
  login(body) {
    return axiosInstance.post("/api/user/authUser", body);
  },
  recuperar(body) {
    return axiosInstance.post("/api/user/resetpassword", body);
  },
  validate() {
    return axiosInstance.get('/api/user/me')

  }
};

/**
 * Validate cpf on transpes's api
 * @param document
 */
/*
export function validateUserDocInTranspesServer(document) {
  return axios.get(this.$apiHq+`/transpes-api/${document}`)
}
*/
/**
 * Validate cpf on transpes's api
 * @param document_cpf
 */
export function validateUserDocInServer(document_cpf) {
  const formData = new FormData();
  formData.append('cpf', document_cpf);
  return axios.post(process.env.VUE_APP_URL+"api/user/document", formData)
  .then((response) => {
    return response.data;
  })
  .catch(() => {
    return false;
  });
}


/**
 * Validate user to bypass transpes validation (flixdemy managers and admins)
 * @param email - string
 * @returns {boolean}
 */
export function isUserAllowedToBypassTranspesValidation(email) {
  const isFlixdemyUser = email.includes('@flixdemy.com');
  const isAuditoreManager = email === 'edipo@auditore.com.brja';
  return  isFlixdemyUser || isAuditoreManager;
}

export function getCep(cep) {
  return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
}

export function decodeToken(encodedToken){
  let token = decode(encodedToken)
  if (!token.exp) {
      return null
  }

  let date = new Date(0)
  date.setUTCSeconds(token.exp)

  return date

}

Vue.prototype.$http = api;
if(sessionStorage.userData){
Vue.prototype.$user = JSON.parse(sessionStorage.userData)
}
Vue.prototype.$apiURL = process.env.VUE_APP_URL
Vue.prototype.$storage = process.env.VUE_APP_STORAGE
Vue.prototype.$titleSite = process.env.VUE_APP_TITLE
Vue.prototype.$subtitleSite = process.env.VUE_APP_SUBTITLE
Vue.prototype.$wlType = process.env.VUE_APP_WL_TYPE
Vue.prototype.$wlToken = process.env.VUE_APP_WL_TOKEN
Vue.prototype.$validDoc = process.env.VUE_APP_VALID_DOC
Vue.prototype.$apiHq = process.env.VUE_APP_API_HQ
Vue.prototype.$TokenChat = process.env.VUE_APP_TOKEN_CHAT
Vue.prototype.$SiteChat = process.env.VUE_APP_TOKEN_SITE_CHAT
Vue.prototype.$Site = process.env.VUE_APP_SITE
// Add a 401 response interceptor
// axiosInstance.interceptors.response.use(function (response) {
//   return response;
// },  error => {
//   if (error.response.status === 401) {
//     this.$store.dispatch("LOGOUT");
//     this.$router.push({ name: "login" });
//   }
// });
