import { render, staticRenderFns } from "./HomeProfessor.vue?vue&type=template&id=3d69e106&scoped=true"
import script from "./HomeProfessor.vue?vue&type=script&lang=js"
export * from "./HomeProfessor.vue?vue&type=script&lang=js"
import style0 from "./HomeProfessor.vue?vue&type=style&index=0&id=3d69e106&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_84f1f4618b294cfa2aad13d74ae0562d/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d69e106",
  null
  
)

export default component.exports