import { render, staticRenderFns } from "./CursosLista.vue?vue&type=template&id=2fbfa231&scoped=true"
import script from "./CursosLista.vue?vue&type=script&lang=js"
export * from "./CursosLista.vue?vue&type=script&lang=js"
import style0 from "./CursosLista.vue?vue&type=style&index=0&id=2fbfa231&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_84f1f4618b294cfa2aad13d74ae0562d/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fbfa231",
  null
  
)

export default component.exports